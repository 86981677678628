






























import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, UserCoupon } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
      matchCoupons: [] as any,
    };
  },
  computed: {
    ...mapState("orders", {
      pendingOrder: "pendingOrder",
    }),
  },
  methods: {
    onRefresh() {
      this.loading = true;
      dispatch
        .couponsMatchUserCoupon(this.pendingOrder)
        .then((res) => {
          this.matchCoupons = res.results;
          this.loading = false;
          if (
            !this.matchCoupons.find(
              (item: any) =>
                item.user_coupon.id === this.pendingOrder.user_coupon_id
            )
          ) {
            dispatch.ordersSetPendingOrder({
              ...this.pendingOrder,
              user_coupon_id: undefined,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.onRefresh();
          dispatch.ordersSetPendingOrder({
            ...this.pendingOrder,
            user_coupon_id: undefined,
          });
        });
    },
    onCouponClick(item: UserCoupon) {
      dispatch.ordersSetPendingOrder({
        ...this.pendingOrder,
        user_coupon_id:
          this.pendingOrder.user_coupon_id === item.id ? undefined : item.id,
      });
    },
  },
  mounted() {
    if (this.pendingOrder.address_id) {
      this.onRefresh();
    } else {
      this.$router.replace(this.$paths.cart);
    }
  },
});
